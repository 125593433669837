// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'bootswatch';

@import '~select2/src/scss/core';

.select2-container--default {
  margin-top: 0.6rem;
  width: 100% !important;

  .select2-selection--single {
    background-color: var(--dark);

    .select2-selection__rendered {
      color: var(--light);
    }
  }

  .select2-results__option[aria-selected="true"] {
    background-color: var(--secondary);
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: var(--gray-dark);
  }
}

.select2-dropdown {
  background-color: var(--dark);
}

a.book-item {
  background: var(--gray-dark);
  box-shadow: 2px 3px 3px #0000004d;
  display: block;
  height: calc(100% - 1.8rem);
  margin-bottom: 1.8rem;
  padding: 1rem;
  transition: .3s ease;

  &:hover {
    box-shadow: 3px 8px 9px #0000004d;
    text-decoration: none;
  }
}

body {
  min-height: 100vh;
}

footer {
  background-color: var(--primary);
  position: fixed;
  bottom: 0;
  width: 100%;

  a {
    color: $danger;
  }
}

h1, h2, h3, h4, h5, h6, .btn {
  text-transform: uppercase;
}
